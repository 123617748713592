export const environment = {
  firebase: {
    projectId: 'playmotur-e5b9b',
    appId: '1:1051449526684:web:ae99701f6f44eea5e8a059',
    databaseURL: 'https://playmotur-e5b9b.firebaseio.com',
    storageBucket: 'playmotur-e5b9b.appspot.com',
    apiKey: 'AIzaSyBCPM0BodKErFg8soGOpgAPoskYYwhHnYA',
    authDomain: 'playmotur-e5b9b.firebaseapp.com',
    messagingSenderId: '1051449526684',
    measurementId: 'G-EGV0SNDZMR',
  },
  production: true,
  apiCallPath:'https://playmotur.com/api2',
  basePath:'https://playmotur.com/',


};
