import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { map, Observable, tap } from 'rxjs';
import { UserFirebaseService } from '../servicios/user-firebase.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard  {
  constructor(private authS: UserFirebaseService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authS.usuario$.pipe(
      // tap(x=>console.log(x)),
      map((user) => !!user && !!user.nivel),
      tap((tieneAcceso) => {
        // console.log({tieneAcceso});
        // console.log('estoy aqui activate jeje');
        if (!tieneAcceso) {
          this.router.navigate(['']);
        }
      })
    );
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authS.usuario$.pipe(
      // tap(x=>console.log(x,'canLoad checkAdminGuard')),
      // acá abajo falta el .IS_ADMIN
      map((user) => !!user && !!user.nivel),

      tap((tieneAcceso) => {
        // console.log('estoy aaaaaaaaaaaaaca');
        if (!tieneAcceso) {
          this.router.navigate(['']);
        }
      })
    );
  }
}
