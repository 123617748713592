<mat-dialog-content>
    <div class="grid">
        <span>
            <mat-icon>delete_outline</mat-icon>
        </span>
        <h3>
            {{data?.titulo?data.titulo:'Estás seguro que deseas eliminar?'}}
            
        </h3>
        <p></p>
        <p>{{data?.mensaje}}</p>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>
        {{data?.closeBtnText?data.closeBtnText:'Cancelar'}}
    </button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial color="warn">
        {{data?.confirmBtnText?data.confirmBtnText:'Eliminar'}}
    </button>
</mat-dialog-actions>