import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CofirmDeleteDialogInputs{
  titulo?:string
  mensaje?:string,
  closeBtnText?:string,
  confirmBtnText?:string,
}

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data:CofirmDeleteDialogInputs
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  
}
