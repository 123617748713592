import { Component, OnInit, } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  ruta:string;
  ruta$:Observable<any>;
  locale = 'es-ES';
  apiUrl =  environment.apiCallPath;


  constructor(){   
  }

  ngOnInit(): void {        
  }




}