import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BlogI, Butaca, Cliente,  Destino, Hotel,  PreReserva, Room, RoomTypeNew, Salida, StartAppCallInputsI, StartAppCallResponseI, SuscriptorI, UsuarioDB } from '../models/interfaces';


@Injectable({
  providedIn: 'root'
})
export class ApiCallService {

  constructor(private http:HttpClient) {}

  apiUrl =  environment.apiCallPath;

  StartApp(inputs:StartAppCallInputsI){
    let data={
      params:inputs,
      funcion:'startApp'
    }
    // console.log({startAppInputs:inputs});
    
    return this.http.post<StartAppCallResponseI>(`${this.apiUrl}/sitio/config.php`, JSON.stringify(data) );
  }

  public runFileManager(
    fx: 'listar'|'mkdir'|'rmdir'|'unlink'|'listarEnProfundidad',
    path: string,
    folderName?:string
  ){
   // lee, hace carpetas , remueve carpetas, remueve archivos, y arma arbol de archivos y carpetas del servidor.
   // listar requiere fx:listar y path: url de la carpeta a buscar files
  
    let obj:any = { function: fx, path };
    if(folderName)obj.folderName=folderName;
    // console.log('runFileManager:', obj);
    return this.http.post(`${this.apiUrl}/sitio/getFiles3.php`, JSON.stringify(obj));
  }

  public uploadImages(data:any) {
    let uploadURL = `${this.apiUrl}/sitio/uploadImages.php`;
    return this.http.post(uploadURL, data, {
      reportProgress: true,
      observe: 'events'
    });
  }
  
  cbuRead(){
    let data={
      params:null,
      funcion:'read'
    }
    return this.http.post(`${this.apiUrl}/sitio/cbu.php`, JSON.stringify(data) );
  }

  registrarVenta(params:PreReserva){
    // console.log('estoy por realizar una preReserva()',params);
    let data={
      params,
      funcion:'registrarVenta'
    }
    return this.http.post(`${this.apiUrl}/sitio/ventas.php`,JSON.stringify(data));
  
  }
  comprobanteReadByClienteIdPaqueteId(cliente_id:number,paquete_id:number){
    // console.log('estoy por realizar una preReserva()',params);
    let data={
      params:{cliente_id,paquete_id},
      funcion:'readByClienteIdPaqueteId'
    }
    console.log({data});
    
    return this.http.post(`${this.apiUrl}/sitio/comprobante.php`,JSON.stringify(data));
  }
  comprobante_readByClienteIdPaqueteIdJoinTipocomprobante(cliente_id:number,paquete_id:number){
    // console.log('estoy por realizar una preReserva()',params);
    let data={
      params:{cliente_id,paquete_id},
      funcion:'readByClienteIdPaqueteIdJoinTipocomprobante'
    }
    console.log({data});
    
    return this.http.post(`${this.apiUrl}/sitio/comprobante.php`,JSON.stringify(data));
  }

 // Config



  

  // Suscriptores
  suscriptores_add(suscriptor:SuscriptorI){
    let data={
      params:{suscriptor},
      funcion:'add'
    }
    return this.http.post(`${this.apiUrl}/sitio/suscriptores.php`, JSON.stringify(data) );
  }
  
  // Blog

  blogRead(){
    let data={
      params:null,
      funcion:'read'
    }
    return this.http.post(`${this.apiUrl}/sitio/blogs.php`, JSON.stringify(data) );
  }
  
  blogSingle(blog_id:number){
    let data={
      params:{blog_id},
      funcion:'single'
    }
    return this.http.post(`${this.apiUrl}/sitio/blogs.php`, JSON.stringify(data) );
  }

 
  usuariosSingle_email(email:string){
    let data={
      params:{email},
      funcion:'single_email'
    }
    return this.http.post(`${this.apiUrl}/sitio/usuarios.php`, JSON.stringify(data) );
  }

  usuarios_registro(usuario:UsuarioDB){
    let data={
      params:{usuario},
      funcion:'registro'
    }
    return this.http.post(`${this.apiUrl}/sitio/usuarios.php`, JSON.stringify(data) );
  }


  // Clientes

  clientesSingle(cliente_id:number){
    let data={
      params:{cliente_id},
      funcion:'single'
    };
    return this.http.post(`${this.apiUrl}/sitio/clientes.php`, JSON.stringify(data) );
  }

  clientesUpdate(cliente:Cliente){
    let data={
      params:cliente,
      funcion:'update'
    };
    return this.http.post(`${this.apiUrl}/sitio/clientes.php`, JSON.stringify(data) );
  }

  clientesAdd(cliente:Cliente){
    let data={
      params:cliente,
      funcion:'add'
    };
    return this.http.post(`${this.apiUrl}/sitio/clientes.php`, JSON.stringify(data) );
  }
  clientes_addAnonimo(cliente:Cliente){
    let data={
      params:cliente,
      funcion:'addAnonimo'
    };
    return this.http.post(`${this.apiUrl}/sitio/clientes.php`, JSON.stringify(data) );
  }

  clientesSingleByDocTipoValor(doc_tipo:string, doc_valor:string){
    let data={
      params:{doc_tipo,doc_valor},
      funcion:'singleByDocTipoValor'
    };
    return this.http.post(`${this.apiUrl}/sitio/clientes.php`, JSON.stringify(data) );
  }

 

  // Salida
  salidasRead(){
    let data={
      params:null,
      funcion:'read'
    };
    return this.http.post(`${this.apiUrl}/sitio/salidas.php`, JSON.stringify(data) );
  }
  salidasAdd(salida:Salida){
    let data={
      params:salida,
      funcion:'add'
    };
    return this.http.post(`${this.apiUrl}/sitio/salidas.php`, JSON.stringify(data) );
  }

  salidasUpdate(salida:Salida){
    let data={
      params:salida,
      funcion:'update'
    };
    return this.http.post(`${this.apiUrl}/sitio/salidas.php`, JSON.stringify(data) );
  }
  
  salidaUpdateWithButacas(salida:Salida){
    let data={
      params:salida,
      funcion:'updateSalidaWithButacas'
    };
    return this.http.post(`${this.apiUrl}/sitio/salidas.php`, JSON.stringify(data) );
  }

  salidasDelete(id:number){
    let data={
      params:{id},
      funcion:'delete'
    };
    return this.http.post(`${this.apiUrl}/sitio/salidas.php`, JSON.stringify(data) );
  }


  // Butacas
  butacasRead(){
    let data={
      params:null,
      funcion:'read'
    };
    return this.http.post(`${this.apiUrl}/sitio/butacas.php`, JSON.stringify(data) );
  }
  butacasAdd(butaca:Butaca){
    let data={
      params:{butaca},
      funcion:'add'
    };
    return this.http.post(`${this.apiUrl}/sitio/butacas.php`, JSON.stringify(data) );
  }

  butacasUpdate(butaca:Butaca){
    let data={
      params:{butaca},
      funcion:'update'
    };
    return this.http.post(`${this.apiUrl}/sitio/butacas.php`, JSON.stringify(data) );
  }

  butacasDelete(id:number){
    let data={
      params:{id},
      funcion:'delete'
    };
    return this.http.post(`${this.apiUrl}/sitio/butacas.php`, JSON.stringify(data) );
  }

  butacasfilterBySalidaId(salida_id:number){
    let data={
      params:{salida_id},
      funcion:'filterBySalidaId'
    };
    return this.http.post(`${this.apiUrl}/sitio/butacas.php`, JSON.stringify(data) );
  }

  butacasAddArray(butacas:Butaca[]){
    let data={
      params:{butacas},
      funcion:'addArray'
    };
    return this.http.post(`${this.apiUrl}/sitio/butacas.php`, JSON.stringify(data) );
  }



  // Hotel
  hotelRead(){
    let data={
      params:null,
      funcion:'read'
    };
    return this.http.post(`${this.apiUrl}/sitio/hotel.php`, JSON.stringify(data) );
  }
  hotelAdd(hotel:Hotel){
    let data={
      params:hotel,
      funcion:'add'
    };
    return this.http.post(`${this.apiUrl}/sitio/hotel.php`, JSON.stringify(data) );
  }

  hotelUpdate(hotel:Hotel){
    let data={
      params:hotel,
      funcion:'update'
    };
    return this.http.post(`${this.apiUrl}/sitio/hotel.php`, JSON.stringify(data) );
  }

  hotelDelete(hotel_id:number){
    let data={
      params:{hotel_id},
      funcion:'delete'
    };
    return this.http.post(`${this.apiUrl}/sitio/hotel.php`, JSON.stringify(data) );
  }
  hotelFilterByDestinoId(destino_id:number){
    let data={
      params:{destino_id},
      funcion:'FilterByDestinoId'
    };
    return this.http.post(`${this.apiUrl}/sitio/hotel.php`, JSON.stringify(data) );
  }

  hotel_single(hotel_id:number){
    let data={
      params:{hotel_id},
      funcion:'single'
    };
    return this.http.post(`${this.apiUrl}/sitio/hotel.php`, JSON.stringify(data) );
  }

  // Rooms

  roomsRead(){
    let data={
      params:null,
      funcion:'read'
    };
    return this.http.post(`${this.apiUrl}/sitio/rooms.php`, JSON.stringify(data) );
  }
  roomsAdd(room:Room){
    let data={
      params:room,
      funcion:'add'
    };
    return this.http.post(`${this.apiUrl}/sitio/rooms.php`, JSON.stringify(data) );
  }

  roomsUpdate(room:Room){
    let data={
      params:room,
      funcion:'update'
    };
    return this.http.post(`${this.apiUrl}/sitio/rooms.php`, JSON.stringify(data) );
  }
  roomsDelete(room_id:number){
    let data={
      params:{room_id},
      funcion:'delete'
    };
    return this.http.post(`${this.apiUrl}/sitio/rooms.php`, JSON.stringify(data) );
  }

  // Roomstype

  roomtypeRead(){
    let data={
      params:null,
      funcion:'read'
    };
    return this.http.post(`${this.apiUrl}/sitio/roomtype.php`, JSON.stringify(data) );
  }
  roomstypeAdd(roomtype:RoomTypeNew){
    let data={
      params:{roomtype},
      funcion:'add'
    };
    return this.http.post(`${this.apiUrl}/sitio/roomtype.php`, JSON.stringify(data) );
  }

  roomstypeUpdate(roomtype:RoomTypeNew){
    let data={
      params:{roomtype},
      funcion:'update'
    };
    return this.http.post(`${this.apiUrl}/sitio/roomtype.php`, JSON.stringify(data) );
  }
  roomtypeDelete(roomtype_id:number){
    let data={
      params:{roomtype_id},
      funcion:'delete'
    };
    console.log({data});
    
    return this.http.post(`${this.apiUrl}/sitio/roomtype.php`, JSON.stringify(data) );
  }

  // Destino

  destinoRead(){
    let data={
      params:null,
      funcion:'read'
    };
    return this.http.post(`${this.apiUrl}/sitio/destino.php`, JSON.stringify(data) );
  }
  destinoAdd(destino:Destino){
    let data={
      params:destino,
      funcion:'add'
    };
    return this.http.post(`${this.apiUrl}/sitio/destino.php`, JSON.stringify(data) );
  }

  destinoUpdate(destino:Destino){
    let data={
      params:destino,
      funcion:'update'
    };
    return this.http.post(`${this.apiUrl}/sitio/destino.php`, JSON.stringify(data) );
  }

  destinoDelete(id:number){
    let data={
      params:{id},
      funcion:'delete'
    };
    return this.http.post(`${this.apiUrl}/sitio/destino.php`, JSON.stringify(data) );
  }

  mercadoPago_crearPreferencia(params:{
    articulos:{
      title:string
      quantity:number
      unit_price:number
    }[],
    cliente:{
      nombre:string
      apellido:string
      mail:string
    },
    fid:number
  }){
    let data={
      params,
      funcion:'crearPreferencia'
    };
    return this.http.post(`${this.apiUrl}/sitio/mercadopago.php`, JSON.stringify(data) );
  }

  verify_paquete(params:{paquete_id:number,code:string,mail:string}){
    let data={
      params,
      funcion:'verify_paquete'
    };
    return this.http.post(`${this.apiUrl}/sitio/paquetes.php`, JSON.stringify(data) );
  }

  cupones_validate(nombre:string){
    let data={
      params:{nombre},
      funcion:'validate'
    };
    return this.http.post(`${this.apiUrl}/sitio/cupones.php`, JSON.stringify(data) );
  }




}
