import { Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  User,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { BehaviorSubject, filter, Observable, tap } from 'rxjs';
import { UsuarioDB } from '../models/interfaces';
import { ApiCallService } from './api-call.service';
import { SharedDialogsService } from './shared-dialogs.service';


export interface UserFormularioI{
  email:string,
  password:string
}

@Injectable({
  providedIn: 'root',
})
export class UserFirebaseService {
  // private auth: Auth;
  private userDataBS: BehaviorSubject<User> = new BehaviorSubject(undefined);
  public userData$: Observable<User> = this.userDataBS.asObservable().pipe(
    filter((x) => x !== undefined),
  );

  private usuarioBS: BehaviorSubject<UsuarioDB> = new BehaviorSubject(undefined);
  public usuario$ = this.usuarioBS.asObservable().pipe(
    filter((x) => x !== undefined),
  );

  constructor(
    private auth:Auth, 
    private router: Router, 
    private apiCall:ApiCallService,
    private dialogsS:SharedDialogsService,
    private sharedDialog:SharedDialogsService

    ) {
    this.auth = getAuth();
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        //existe el usuario

        this.userDataBS.next(user);
        // console.log({user});
        
        // busco con apical mi usuario en la base de datos por el correo.
        apiCall.usuariosSingle_email(user.email).subscribe(res=>{
          console.log({res});
          if(res['response']?.length){
            this.usuarioBS.next(res['response'][0]);
          }else{
            this.dialogsS.openAlertaDialog({mensaje:"Usted todavía no presenta los permisos para ingresar al modulo de ADMINISTRACION. Pase su correo a la Oficina para poder otorgarle los permisos. Cuando los mismos sean otorgados, solo deberá cargar nuevamente el sitio para poder navegar al modulo Admin"})
            this.router.navigate(['/']);

           
          }
        })
        // luego lo transmito mediante:
        //this.usuarioBS.next(usuarioDB)
      } else {
        // no existe
        this.userDataBS.next(null);
        this.usuarioBS.next(null);
      }
    });
  }

  nextUsuario(usuario: UsuarioDB) {
    // es un setter que habilita setear algo que es privado, de este servicio unicamente.
    this.usuarioBS.next(usuario);
  }

  crearUsuarioConEmail(email, password,nombre:string) {
    createUserWithEmailAndPassword(this.auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      this.apiCall.usuarios_registro({nombre,email}).subscribe(
        (res) => {
        console.log({res});            
        },
        (error)=>{
          console.log(error);
          this.sharedDialog.openAlertaDialog({mensaje:'No se pudo crear el usuario en Don WEB. Posiblemente su correo o su dni ya se encuentra registrado en el servidor del Sitio. Comuniquese con el Desarrollador para ver el caso.'})
        }
      );
    })
    .catch((error) => {
        console.log({error});
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        this.dialogsS.openAlertaDialog({mensaje:this.firebaseError(errorCode)})
      });
    }

  loguearConGoogle() {
    const provider = new GoogleAuthProvider();
    console.log('estoy corriendo');

    signInWithPopup(this.auth, provider)
      .then((result) => {
        console.log({ usuario: result.user });
        const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // const user = result.user;
        this.router.navigate(['/admin'])

        // chequeo si ya esta en la tabla usuario, sino, tengo que enviarlo a registrase
        // this.apiCall.usuarioSingleEmail(user.email).subscribe(resp=>{
          //   let usuarioArray:UsuarioI[]= resp['response'];
          //   if(usuarioArray.length){ // ya tiene usuario
          //     this.usuarioBS.next(usuarioArray[0]);
          //   }else{
            //     this.router.navigate(['registrarse','google']);
            //   }
            // })
          })
          .catch((error) => {
            // Handle Errors here.
            console.log({ error });

            const errorCode = error.code;
            const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  signUp(usuario:UserFormularioI) {
    const {email , password } = usuario; //destructuring!

    return createUserWithEmailAndPassword(this.auth, email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // ...
      this.router.navigate(['cliente/paquetes'])
    })
      .catch((error) => {
        console.log({ error });
        const errorCode = error.code;
        const errorMessage = error.message;
        this.dialogsS.openAlertaDialog({mensaje:this.firebaseError(errorCode)})

        // ..
      });
  }

  loginByEmail(user: { email: string; password: string }) {
    const { email, password } = user; //destructuring!
    return signInWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
        this.router.navigate(['/admin']);

      })
      .catch((error) => {
        console.log({ error });
        const errorCode = error.code;
        const errorMessage = error.message;
        this.dialogsS.openAlertaDialog({mensaje:this.firebaseError(errorCode)})

      });
  }

  resetearPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  logout() {
    signOut(this.auth)
      .then(() => {
        console.log('Sign-out successful.');
        this.router.navigate(['']);
      })
      .catch((error) => {
        console.log(' An error happened.');
      });
  }

  firebaseError(code: string) {
    switch (code) {
      case 'auth/wrong-password':
        return 'La contraseña ingresada es inválida.';
      case 'auth/email-already-in-use':
        return 'El correo electrónico ingresado ya ha sido usado.';
      case 'auth/invalid-email':
        return 'El correo ingresado es inválido.';
      case "auth/user-not-found":
        return "Usuario Inexistente. Verifique si está bien escrito o Regístrese nuevamente"
      case "auth/weak-password":
        return "Contraseña debil. Debe tener al menos 6 caracteres"
      default:
        return 'Error desconocido';
    }
  }
}
