import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface AlertaDialogInputs{
  mensaje:string
  titulo?:string
  icono?:string
  aceptarBtn?:boolean
}

@Component({
    selector: 'my-dialog',
    template: `<h1 mat-dialog-title> <mat-icon>{{data.icono?data.icono:'info'}}</mat-icon>{{data.titulo?data.titulo:'Atención:'}}</h1>
                <div mat-dialog-content>
                  <p>{{data.mensaje}}</p>
                  <button mat-raised-button (click)="onClose()" class="" color="primary">Cerrar</button>
                  @if (data.aceptarBtn) {
                    <button mat-raised-button color="accent" [matDialogClose]="true" class="">Aceptar</button>
                  }
                </div>`,
  })
  export class AlertaDialog {
    constructor(
      @Inject(MAT_DIALOG_DATA) public data:AlertaDialogInputs,
      public dialogRef: MatDialogRef<AlertaDialog>
    ) { }
    onClose(): void {
      this.dialogRef.close();
    }
  }