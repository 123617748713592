import { Injectable } from '@angular/core';
import { AlertaDialog, AlertaDialogInputs } from '../shared/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs';
import { CofirmDeleteDialogInputs, ConfirmDeleteComponent } from '../admin/shared/confirm-delete/confirm-delete.component';

@Injectable({
  providedIn: 'root'
})
export class SharedDialogsService {

  constructor(public dialog: MatDialog) { }

  openAlertaDialog(data:AlertaDialogInputs) {    
    const dialogRef = this.dialog.open(AlertaDialog, {
      // width: '250px',
      data,
    });
    return dialogRef.afterClosed().pipe(map(x=>!!x))
  }

  openConfirmDeleteDialog(data?:CofirmDeleteDialogInputs) {
    const dialogRef = this.dialog.open(ConfirmDeleteComponent, {
      width: '400px',
      data
    });
    return dialogRef.afterClosed();
  }


}
