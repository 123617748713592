import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';

const routes: Routes = [
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AdminGuard], canLoad: [AdminGuard]},
  { path: 'destino/:id/:salidaId', loadChildren: () => import('./destino/destino.module').then(m => m.DestinoModule) },
  { path: 'entradas', loadChildren: () => import('./blog/blog.module').then(m => m.BlogModule) },
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'cliente', loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule)},
  { path: 'usuario', loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule)},
  { path: '**', redirectTo:'' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration:'disabled', anchorScrolling:'enabled',scrollOffset:[0, 60]})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
